<script lang="ts" setup>
  import { AppSideBar, AppHeader } from '@/components'
  import { onMounted, onUnmounted, ref, watch } from 'vue'
  import { Close, Menu } from '@element-plus/icons-vue'
  import { useRoute } from 'vue-router'

  const isMobileMenuOpen = ref(false)
  const isMobile = ref(false)
  const route = useRoute()

  const checkMobile = () => {
    isMobile.value = window.innerWidth < 768
  }

  const handleTouchStart = (e: TouchEvent) => {
    // Only prevent default if menu is open and touch is on menu or backdrop
    if (
      isMobileMenuOpen.value &&
      (e.target as HTMLElement).closest('.el-aside, .backdrop')
    ) {
      e.preventDefault()
    }
  }

  // Check on mount and window resize
  onMounted(() => {
    checkMobile()
    window.addEventListener('resize', checkMobile)

    if (typeof document !== 'undefined') {
      document.addEventListener('touchstart', handleTouchStart, {
        passive: false
      })
    }
  })

  onUnmounted(() => {
    window.removeEventListener('resize', checkMobile)

    if (typeof document !== 'undefined') {
      document.removeEventListener('touchstart', handleTouchStart)
    }
  })

  const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value
  }

  const closeMobileMenu = async () => {
    isMobileMenuOpen.value = false
    // Force a repaint to ensure proper transition
    await new Promise((resolve) => requestAnimationFrame(resolve))
    // Add a small delay to ensure the menu is fully closed
    await new Promise((resolve) => setTimeout(resolve, 300))
  }

  const handleMenuClick = async () => {
    if (isMobile.value) {
      await closeMobileMenu()
    }
  }

  // Watch for route changes to close mobile menu
  watch(
    () => route.path,
    () => {
      if (isMobileMenuOpen.value) {
        closeMobileMenu()
      }
    }
  )
</script>

<template>
  <div class="common-layout">
    <el-container>
      <!-- Mobile menu button -->
      <button
        v-if="isMobile"
        class="fixed z-50 p-2 transition-colors rounded-lg top-4 left-4 hover:bg-gray-100 active:bg-gray-200"
        @click="toggleMobileMenu"
        @touchend.prevent="toggleMobileMenu"
        aria-label="Tắt mở menu"
      >
        <el-icon size="24">
          <Menu v-if="!isMobileMenuOpen" />
          <Close v-else />
        </el-icon>
      </button>

      <!-- Sidebar - overlay on mobile, fixed on desktop -->
      <el-aside
        :class="{
          'fixed inset-y-0 left-0 z-50': isMobile,
          'translate-x-0': isMobileMenuOpen || !isMobile,
          '-translate-x-full': !isMobileMenuOpen && isMobile,
          'touch-manipulation': isMobile,
          'will-change-transform': isMobile,
          relative: !isMobile
        }"
        :style="{
          width: '250px',
          transform:
            isMobile && !isMobileMenuOpen ? 'translateX(-100%)' : 'none'
        }"
        class="transition-transform duration-300 ease-in-out bg-white"
      >
        <div class="relative h-full">
          <AppSideBar @menu-click="handleMenuClick" />
          <button
            v-if="isMobile"
            class="absolute p-2 transition-colors rounded-lg top-4 right-4 hover:bg-gray-100 active:bg-gray-200"
            @click="closeMobileMenu"
            @touchend.prevent="closeMobileMenu"
            aria-label="Đóng menu"
          >
            <el-icon size="20">
              <Close />
            </el-icon>
          </button>
        </div>
      </el-aside>

      <!-- Backdrop - mobile only -->
      <div
        v-if="isMobile && isMobileMenuOpen"
        class="fixed inset-0 z-30 bg-black bg-opacity-50 cursor-pointer"
        @click="closeMobileMenu"
        @touchend.prevent="closeMobileMenu"
        role="button"
        tabindex="0"
        aria-label="Đóng menu"
      />

      <el-container class="flex flex-col items-start w-full h-screen">
        <el-header class="w-full h-full !px-0">
          <AppHeader />
        </el-header>
        <el-main class="w-full h-full !p-0 bg-lightGray">
          <slot />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<style scoped>
  .el-aside {
    @apply bg-white;
  }

  /* Remove the media query that was forcing transform none */
  /* Instead handle desktop layout with relative positioning */

  .touch-manipulation {
    touch-action: pan-y pinch-zoom;
    -webkit-overflow-scrolling: touch;
    will-change: transform;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000;
  }

  /* Only apply fixed positioning on mobile */
  :deep(.el-aside) {
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    overflow-y: auto;
  }

  /* Apply fixed positioning only on mobile */
  :deep(.el-aside.fixed) {
    position: fixed;
  }

  /* Prevent unwanted touch behaviors */
  button {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    touch-action: manipulation;
  }

  /* Ensure proper layout on desktop */
  .el-container {
    @apply flex;
  }

  /* Add padding to main content on desktop to account for sidebar */
  @media (min-width: 768px) {
    .el-container .el-container {
      margin-left: 0px;
    }
  }
</style>
